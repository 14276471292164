<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-brawl-stars-96.png"
          class="h-20 ml-2 inline-block"
        />
        خرید ربات کانال
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <!-- green -->
      <div
        class="
          flex flex-wrap
          bg-blueGray-100
          border border-blueGray-200
          p-3
          rounded-md
          mb-4
        "
      >
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="flex justify-center p-3">
            <img src="@/assets/images/green-book_1f4d7.png" class="h-28" />
          </div>
          <h2 class="text-lg font-bold">تعرفه سبز</h2>
          <p>با خرید این تعرفه ربات دیگر به کانال شما تبلیغ ارسال نمی‌کند</p>
        </div>
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">تبلیغات</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">امکانات ویژه</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">پشتیبانی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">ربات اختصاصی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
        </div>
        <div class="w-full grid sm:grid-cols-3 gap-3 mt-4">
          <Button
            class="text-lg"
            color="cyan"
            :disabled="sending"
            @click="submit('green', 1)"
          >
            1 ماهه {{ $number.format(L_.find(prices.green, ["month", 1])?.price) }} تومان
          </Button>
          <Button
            class="text-lg"
            color="teal"
            :disabled="sending"
            @click="submit('green', 3)"
          >
            3 ماهه {{ $number.format(L_.find(prices.green, ["month", 3])?.price) }} تومان
          </Button>
          <Button
            class="text-lg"
            color="green"
            :disabled="sending"
            @click="submit('green', 6)"
          >
            6 ماهه {{ $number.format(L_.find(prices.green, ["month", 6])?.price) }} تومان
          </Button>
        </div>
      </div>

      <!-- blue -->
      <div
        class="
          flex flex-wrap
          bg-blueGray-100
          border border-blueGray-200
          p-3
          rounded-md
          mb-4
        "
      >
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="flex justify-center p-3">
            <img src="@/assets/images/blue-book_1f4d8.png" class="h-28" />
          </div>
          <h2 class="text-lg font-bold">تعرفه آبی</h2>
          <p>با خرید این تعرفه از امکانات ویژه ربات در کانال بهره‌مند می‌شوید</p>
        </div>
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">تبلیغات</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">امکانات ویژه</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">پشتیبانی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">ربات اختصاصی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
        </div>
        <div class="w-full grid sm:grid-cols-3 gap-3 mt-4">
          <Button class="text-lg" color="cyan" @click="submit('blue', 1)">
            1 ماهه {{ $number.format(L_.find(prices.blue, ["month", 1])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="teal" @click="submit('blue', 3)">
            3 ماهه {{ $number.format(L_.find(prices.blue, ["month", 3])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="green" @click="submit('blue', 6)">
            6 ماهه {{ $number.format(L_.find(prices.blue, ["month", 6])?.price) }} تومان
          </Button>
        </div>
      </div>

      <!-- bronze -->
      <div
        class="
          flex flex-wrap
          bg-blueGray-100
          border border-blueGray-200
          p-3
          rounded-md
          mb-4
        "
      >
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="flex justify-center p-3">
            <img src="@/assets/images/3rd-place-medal_1f949.png" class="h-28" />
          </div>
          <h2 class="text-lg font-bold">تعرفه برنزی</h2>
          <p>
            با خرید این تعرفه هم از امکانات ویژه ربات در کانال بهره‌مند میشوید و ربات هم
            در کانال شما تبلیغات ارسال نمی‌کند
          </p>
        </div>
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">تبلیغات</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">امکانات ویژه</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">پشتیبانی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">ربات اختصاصی</div>
            <div class="p-3 w-1/2 text-red-500 font-bold">ندارد</div>
          </div>
        </div>
        <div class="w-full grid sm:grid-cols-3 gap-3 mt-4">
          <Button class="text-lg" color="cyan" @click="submit('bronze', 1)">
            1 ماهه {{ $number.format(L_.find(prices.bronze, ["month", 1])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="teal" @click="submit('bronze', 3)">
            3 ماهه {{ $number.format(L_.find(prices.bronze, ["month", 3])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="green" @click="submit('bronze', 6)">
            6 ماهه {{ $number.format(L_.find(prices.bronze, ["month", 6])?.price) }} تومان
          </Button>
        </div>
      </div>

      <!-- silver -->
      <div
        class="
          flex flex-wrap
          bg-blueGray-100
          border border-blueGray-200
          p-3
          rounded-md
          mb-4
        "
      >
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="flex justify-center p-3">
            <img src="@/assets/images/2nd-place-medal_1f948.png" class="h-28" />
          </div>
          <h2 class="text-lg font-bold">تعرفه نقره‌ای</h2>
          <p>این تعرفه علاوه بر امکانات تعرفه برنزی شامل پشتیبانی نیز می‌شود</p>
        </div>
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">تبلیغات</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">امکانات ویژه</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">پشتیبانی</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">ربات اختصاصی</div>
          </div>
        </div>
        <div class="w-full grid sm:grid-cols-3 gap-3 mt-4">
          <Button class="text-lg" color="teal" @click="submit('silver', 3)">
            3 ماهه {{ $number.format(L_.find(prices.silver, ["month", 3])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="green" @click="submit('silver', 6)">
            6 ماهه {{ $number.format(L_.find(prices.silver, ["month", 6])?.price) }} تومان
          </Button>
          <Button class="text-lg" color="amber" @click="submit('silver', 12)">
            12 ماهه
            {{ $number.format(L_.find(prices.silver, ["month", 12])?.price) }} تومان
          </Button>
        </div>
      </div>

      <!-- golden -->
      <div
        class="
          flex flex-wrap
          bg-blueGray-100
          border border-blueGray-200
          p-3
          rounded-md
          mb-4
        "
      >
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="flex justify-center p-3">
            <img src="@/assets/images/1st-place-medal_1f947.png" class="h-28" />
          </div>
          <h2 class="text-lg font-bold">تعرفه طلایی</h2>
          <p>در این تعرفه می‌توانید ربات اختصاصی خود را داشته باشید</p>
          <p class="text-red-500">این تعرفه در حال حاظر موجود نیست</p>
        </div>
        <div class="w-full sm:w-1/2 text-center p-2">
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">تبلیغات</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">ندارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">امکانات ویژه</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">پشتیبانی</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
          <div class="even:bg-blueGray-200 flex">
            <div class="p-3 w-1/2">ربات اختصاصی</div>
            <div class="p-3 w-1/2 text-green-500 font-bold">دارد</div>
          </div>
        </div>
        <div class="w-full grid sm:grid-cols-2 gap-3 mt-4">
          <Button :disabled="true" class="text-lg" color="green">
            6 ماهه {{ $number.format(L_.find(prices.golden, ["month", 6])?.price) }} تومان
          </Button>
          <Button :disabled="true" class="text-lg" color="amber">
            12 ماهه
            {{ $number.format(L_.find(prices.golden, ["month", 12])?.price) }} تومان
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";
import Icon from "@/components/icon/Icon.vue";
import _ from "lodash";

export default {
  components: { BodySimple, Button, Icon },
  data() {
    return {
      firstLoading: true,
      sending: false,
      prices: {},
      channel: {},
      L_: _,
    };
  },
  mounted() {
    this.getData();
    this.getPrices();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    getPrices() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(`api/owner-channels/${$this.$route.params.channelId}/buy`, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.prices = response.data.prices;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.sending = false;
        });
    },
    submit(type, month) {
      // console.log(type, month);
      let $this = this;
      $this.sending = true;
      // $this.firstLoading = true;
      $this.$axios
        .post(`/api/owner-channels/${$this.$route.params.channelId}/buy`, {
          id: $this.$store.state.user.id,
          type: type,
          month: month,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "ChannelOwnerBuyCheck",
            params: {
              id: $this.$store.state.user.id,
              channelId: $this.$route.params.channelId,
              payId: response.data.pay.id,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>
